/**
 * Created by Rain Laansalu on 12.03.2021.
 */
import React from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import et from 'date-fns/locale/et';
import { EditorProps, Row } from 'react-data-grid';

export default function DateInputEditor({ row, column, onRowChange, left, top }: EditorProps<Row>) {
  const [value, setValue] = React.useState(new Date(row[column.key]));
  const type = column.params.type;

  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  };
  if (type === 'DATETIME') {
    options.hour = '2-digit';
    options.minute = '2-digit';
    options.second = '2-digit';
  }

  const changeDate = (date = false) => {
    // console.log(date.toLocaleTimeString('et-EE', options));
    const dateString =
      type === 'DATETIME'
        ? date
            .toISOString()
            .replace('Z', '')
            .replace('T', ' ')
        : date.toISOString().split('T')[0];
    // console.log(dateString);
    onRowChange({ ...row, [column.key]: dateString }, true);
    // onRowChange({ ...row, [column.key]: date.toISOString().split('T')[0] }, true);
  };
  const dateformat = type === 'DATETIME' ? 'dd.MM.yyyy HH:mm:ss' : 'dd.MM.yyyy';
  const ExampleCustomTimeInput = ({ date, value, onChange }) => {
    // console.log(date);
    // console.log(value);
    return (
      // <input
      //   value={value}
      //   disabled={true}
      //   // onChange={e => onChange(e.target.value)}
      // />
      <p>{date.toLocaleTimeString('et-EE')}</p>
    );
  };
  if (type === 'DATETIME') {
    return (
      <div style={{ position: 'absolute', left: left, top: top }}>
        <DatePicker
          autoFocus={true}
          selected={value}
          onKeyDown={event => {
            if (event.keyCode === 13 && event.target.nodeName === 'INPUT' && event.target.type === 'text') {
              changeDate(value);
            }
          }}
          onChange={date => {
            setValue(date);
          }}
          onSelect={date => {
            changeDate(date);
          }}
          dateFormat="dd.MM.yyyy HH:mm:ss"
          // dateFormat="Ppp"
          // timeFormat="p"
          locale={et}
          showTimeInput
          customTimeInput={<ExampleCustomTimeInput />}
          timeInputLabel="Kellaaeg:">
          {/*<div style={{ color: 'red' }}>Don't forget to check the weather!</div>*/}
        </DatePicker>
      </div>
    );
  }
  return (
    <div style={{ position: 'absolute', left: left, top: top }}>
      <DatePicker
        autoFocus={true}
        selected={value}
        onKeyDown={event => {
          if (event.keyCode === 13 && event.target.nodeName === 'INPUT' && event.target.type === 'text') {
            changeDate(value);
          }
        }}
        onChange={date => {
          setValue(date);
        }}
        onSelect={date => {
          changeDate(date);
        }}
        dateFormat="dd.MM.yyyy"
        locale={et}
      />
    </div>
  );
}
