import axios from 'axios';
// export default getAxios('auth');
// import axios from 'axios';
//
// const baseURL = `https://192.168.1.204:8000/`;
const baseURL = process.env.NODE_ENV === 'production' ? 'https://dev.bevira.com/api/' : `https://api.beviralocal.com/`;
// const baseURL = `https://dev.bevira.com/api/`;
export default axios.create({
  baseURL: baseURL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    From: 'rain@bevira.com',
    IsDevelop: process.env.NODE_ENV === 'production' ? 0 : 1,
  },
});
