/**
 * Created by Rain Laansalu on 21.12.2020.
 */
// *https://www.registers.service.gov.uk/registers/country/use-the-api*
import fetch from 'cross-fetch';
import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import throttle from 'lodash/throttle';
import { searchAutocomplete } from '../../../redux/actions';
import { EditorProps, Row } from 'react-data-grid';

function sleep(delay = 0) {
  return new Promise(resolve => {
    setTimeout(resolve, delay);
  });
}

export default function Asynchronous({ row, column, onRowChange, onClose, left, top }: EditorProps<Row>) {
  const columnData = column.params;
  const [didMount, setDidMount] = useState(false);

  const [inputValue, setInputValue] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const loading = open && options.length === 0;
  const [placeholder, setPlaceholder] = useState(
    row[column.key + '__name'] ? row[column.key + '__name'] : row[column.key] ? row[column.key] : 'Otimiseks kirjuta',
  );

  React.useEffect(() => {
    setDidMount(true);
    return () => setDidMount(false);
  }, []);

  if (!didMount) {
    return null;
  }

  function getSuggestions(searchTerm) {
    setOptions([]);
    if (searchTerm !== inputValue && searchTerm.length >= 3) {
      searchAutocomplete(searchTerm, columnData.searchsource, function(data) {
        setInputValue(searchTerm);
        setOptions(data);
      });
    }
    // return suggestions;
  }

  return (
    <Autocomplete
      id="asynchronous-demo"
      style={{ width: 300, position: 'absolute', left: left, top: top, backgroundColor: 'white' }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      // getOptionSelected={(option, value) => option.name === value.name}
      // getOptionLabel={option => option.name}
      onChange={(event, newValue) => {
        // setOptions(newValue ? [newValue, ...options] : options);
        onRowChange({ ...row, [column.key]: newValue.id }, true);
      }}
      getOptionLabel={option => {
        return option.label;
      }}
      options={options}
      loading={loading}
      onInputChange={(event, newInputValue) => {
        getSuggestions(newInputValue);
        // setInputValue(newInputValue);
      }}
      renderInput={params => {
        return (
          <TextField
            autoFocus={true}
            {...params}
            label={columnData.caption}
            variant="outlined"
            placeholder={placeholder}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        );
      }}
    />
  );
}
