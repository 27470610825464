/**
 * Created by Rain Laansalu on 18.03.2021.
 */
import { EditorProps } from 'react-data-grid';
import React, {useState} from 'react';
import { TextField } from '@material-ui/core';

export default function TextAreaEditor<TRow, TSummaryRow = unknown>({
  row,
  column,
  onRowChange,
  left,
  top,
}: EditorProps<TRow, TSummaryRow>) {
  const [value, setValue] = React.useState(row[column.key]);
  const [disabled, setDisabled] = useState(true);
  const handleChange = event => {
    // console.log(event);
    // console.log(event);
    // console.log(event.target.value);
    if (disabled) {
      setDisabled(false);
      return;
    }
    setValue(event.target.value);
  };
  const submitChanges = valueToSubmit => {
    onRowChange({ ...row, [column.key]: valueToSubmit }, true);
  };
  // console.log(row);
  // console.log(column);
  // console.log(onRowChange);
  // console.log(onClose);
  // console.log(left);
  // console.log(top);
  return (
    <TextField
      style={{ position: 'absolute', left: left, top: top, backgroundColor: '#FFF', minWidth: '30rem' }}
      id="outlined-multiline-static"
      label="Multiline"
      // InputProps={{ style: { resize: 'auto' } }}
      // disabled={disabled}
      autoFocus={true}
      multiline
      rows={8}
      // defaultValue={value}
      value={value}
      // variant="outlined"
      // onBlur={() => submitChanges(value)}
      // onChange={console.log('tere')}
      // onFocus={event => {
      //   setDisabled(false);
      // }}
      onChange={handleChange}
      onKeyDown={event => {
        if (disabled) {
          console.log('setDisabled false');
          setDisabled(false);
        }
        if (event.code !== undefined) {
          if (event.code === 'Enter' || event.code === 'NumpadEnter') {
            if (!event.shiftKey) {
              submitChanges(value);
            } else {
              event.stopPropagation();
            }
          }
        }
      }}
    />
  );
}
