/**
 * Created by Rain Laansalu on 17.12.2020.
 */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DataGrid, { Column, FormatterProps, Row, TextEditor } from 'react-data-grid';
import { getWorkingsheetDataFromBE, updateWorkingSheetRow } from '../../../../redux/actions/WorkingSheet';
import { useParams } from 'react-router-dom';
import { SHEET_DATA, SHEET_DATA_CHANGE_ROW } from '../../../../@jumbo/constants/ActionTypes';
import Asynchronous from '../../../../@workingSheet/components/editors/Asynchronous';
import SearchInputFormatter from '../../../../@workingSheet/components/formatters/SearchInputFormatter';
import DateInputEditor from '../../../../@workingSheet/components/editors/DateInputEditor';
import DateTimeFormatter from '../../../../@workingSheet/components/formatters/DateTimeFormatter';
import IdFormatter from '../../../../@workingSheet/components/formatters/IdFormatter';
import TextAreaEditor from '../../../../@workingSheet/components/editors/TextAreaEditor';

function createColumns(columns) {
  return columns.map(function(column) {
    // return {key: column.field, name: column.caption, formatter: CustomFormatter}
    // console.log(column);
    // let width = null;
    // let resizable = true;
    let editable = true;
    if (column.elementtype === 'readonly' || column.nochange) {
      editable = false;
    }
    // if (column.colstyle) {
    //   width = typeof column.colstyle.width !== 'undefined' ? column.colstyle.width : width;
    //   resizable = typeof column.colstyle.resizable !== 'undefined' ? column.colstyle.resizable : resizable;
    //   editable = typeof column.colstyle.editable !== 'undefined' ? column.colstyle.editable : editable;
    // }
    return {
      key: column.field,
      name: column.caption,
      params: column,
      // resizable:true,
      editable: editable,
      // resizable: resizable,
      // resizable: ((typeof column.resizable !== 'undefined') ? column.resizable : true),
      // editable:(column.field === 'description')
      // width: width,
      width: 180,
      editor: columnEditor(column),
      editorOptions: columnEditorOptions(column),
      // editorOptions: {
      //   // editOnClick: true,
      //   createPortal: false,
      // },
      formatter: customFormatter(column),
      // otherData: column
      // elementtype
    };
  });
}

function customFormatter(column) {
  // console.log(column);
  switch (column.elementtype) {
    // case 'combo': {
    //   return <ComboFormatter columndData={column}/>
    //   // return <ComboFormatter/>
    // }
    case 'searchinput': {
      return p => <SearchInputFormatter params={p} />;
    }
    case 'datepicker': {
      return p => <DateTimeFormatter params={p} type={column.type} />;
      // return p => <DateFormatter params={p} />;
    }
    // case 'image': {
    //   return <ImageFormatter/>
    // }
    default: {
    }
  }
  switch (column.type) {
    case 'DATETIME': {
      return p => <DateTimeFormatter params={p} type={column.type} />;
    }
    default: {
    }
  }
  if (column.field === 'cloneid') {
    return p => <IdFormatter params={p} aliasfield={column.aliasfield} />;
  }
  return null;
}

function columnEditor(column) {
  switch (column.elementtype) {
    case 'searchinput': {
      // return p => <Asynchronous params={p} />;
      return Asynchronous;
    }
    case 'datepicker': {
      return DateInputEditor;
    }
    case 'text':
    case 'longtext': {
      return TextAreaEditor;
    }
    default: {
      return TextEditor;
    }
  }
}

function columnEditorOptions(column) {
  let editorOptions = {};
  switch (column.elementtype) {
    case 'text':
    case 'longtext':
    case 'datepicker':
    case 'searchinput': {
      editorOptions.createPortal = true;
      // editorOptions.editOnClick = false;
      break;
    }
    default: {
      editorOptions.createPortal = false;
    }
  }
  // console.log(editorOptions);
  return editorOptions;
}

function rowKeyGetter(row: Row) {
  return row.id;
}

let activeCell = { idx: 0, rowIdx: 0 };
const BDataGrid = () => {
  const { id } = useParams(); // id from URL
  const workingsheet = useSelector(({ workingSheet }) => workingSheet);
  const dispatch = useDispatch();

  function setActiveCell(pos) {
    activeCell = pos;
  }
  function setRows(rows) {
    // console.log(activeCell);
    // console.log(workingsheet.data.columns[activeCell.idx].field);
    // dispatch(updateWorkingSheetRow(id, rows[activeCell.rowIdx].id, rows[activeCell.rowIdx]));
    // console.log(rows[activeCell.rowIdx]);
    // console.log(rows[activeCell.rowIdx][workingsheet.data.columns[activeCell.idx].field]);
    dispatch({ type: SHEET_DATA_CHANGE_ROW, payload: rows[activeCell.rowIdx] });
    dispatch(
      updateWorkingSheetRow(id, rows[activeCell.rowIdx].id, {
        [workingsheet.data.columns[activeCell.idx].field]:
          rows[activeCell.rowIdx][workingsheet.data.columns[activeCell.idx].field],
      }),
    );
  }
  useEffect(() => {
    dispatch(getWorkingsheetDataFromBE(id));
  }, [id]);

  if (!workingsheet || !workingsheet.data || workingsheet.data.id !== id) {
    return <div></div>;
  }

  return (
    <div>
      <DataGrid
        className="rdg-light fill-grid"
        columns={createColumns(workingsheet.data.columns)}
        // rowGetter
        // rows={workingsheet.data.rows}
        rows={workingsheet.rows}
        onRowsChange={setRows}
        // onSelectedCellChange: function(pos) { console.log }
        // onSelectedRowsChange={setRows}
        onSelectedCellChange={setActiveCell}
        defaultColumnOptions={{
          sortable: true,
          resizable: true,
        }}
        rowHeight={27}
        headerRowHeight={50}
        enableCellSelect={true}
        // editorPortalTarget="body.layout-type-fullwidth"
        style={{
          maxHeight: '93vh',
          height:
            !workingsheet.rows || workingsheet.rows.length === 0 ? undefined : (workingsheet.rows.length + 1) * 27 + 42,
        }}
      />
    </div>
  );
};

export default BDataGrid;
