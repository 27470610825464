/**
 * Created by Rain Laansalu on 16.12.2020.
 */
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  SHEET_CONTENT,
  SHEET_DATA,
  SHEET_DATA_CHANGE_ROW,
  SHEET_DATA_DELETE_ROW,
  SHEET_INCREASE_QNT,
} from '../../@jumbo/constants/ActionTypes';
import axios from '../../services/auth/jwt/config';

export const changeRandomString = newString => {
  return dispatch => {
    dispatch({
      type: 'add_random_string',
      payload: newString,
    });
  };
};

export const increaseQnt = () => {
  return dispatch => {
    dispatch({
      type: SHEET_INCREASE_QNT,
    });
  };
};

export const getWorkingsheetDataFromBE = workingSheetId => {
  return dispatch => {
    dispatch({ type: FETCH_START });
    axios
      .get('workingSheets/getData', {
        params: {
          workingSheetId: workingSheetId,
        },
      })
      .then(({ data }) => {
        // console.log(data);
        // console.log("userSignIn: ", data);
        if (data.result) {
          // localStorage.setItem("sheets", JSON.stringify(data.user));
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: SHEET_DATA, payload: data.data });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.error });
        }
      })
      .catch(function(error) {
        if (error.response) {
          // dispatch({type: SIGNOUT_USER_SUCCESS});
          dispatch({
            type: FETCH_ERROR,
            payload: error.response.statusText + ' ' + error.response.status + ' ' + error.response.data.error,
          });
        } else {
          // dispatch({type: SIGNOUT_USER_SUCCESS});
          dispatch({ type: FETCH_ERROR, payload: error.message });
        }
        console.log('Error****:', error.message);
      });
  };
};

export const deleteWorkingSheetRow = (workingSheetId, rowId) => {
  return dispatch => {
    dispatch({ type: FETCH_START });
    axios
      .post('workingSheets/deleteRow', {
        workingSheetId: workingSheetId,
        rowId: rowId,
      })
      .then(({ data }) => {
        if (data.result) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: SHEET_DATA_DELETE_ROW,
            payload: data.data,
          });
          dispatch({
            type: FETCH_ERROR,
            payload: 'testin kustutamiset',
          });
          // localStorage.setItem("sheets", JSON.stringify(data.user));
          // dispatch({type: SHEET_DATA, payload: data.data});
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.error });
        }
      })
      .catch(function(error) {
        if (error.response) {
          // dispatch({type: SIGNOUT_USER_SUCCESS});
          dispatch({
            type: FETCH_ERROR,
            payload: error.response.statusText + ' ' + error.response.status + ' ' + error.response.data.error,
          });
        } else {
          // dispatch({type: SIGNOUT_USER_SUCCESS});
          dispatch({ type: FETCH_ERROR, payload: error.message });
        }
        console.log('Error****:', error.message);
      });
  };
};

export const updateWorkingSheetRow = (workingSheetId, rowId, updated) => {
  return dispatch => {
    dispatch({ type: FETCH_START });
    axios
      .post('workingSheets/changeRowData', {
        workingSheetId: workingSheetId,
        rowId: rowId,
        updated: updated,
      })
      .then(({ data }) => {
        // console.log(data);
        // console.log("userSignIn: ", data);
        if (data.result) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: SHEET_DATA_CHANGE_ROW,
            payload: data.data,
          });
          // localStorage.setItem("sheets", JSON.stringify(data.user));
          // dispatch({type: SHEET_DATA, payload: data.data});
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.error });
        }
      })
      .catch(function(error) {
        if (error.response) {
          // dispatch({type: SIGNOUT_USER_SUCCESS});
          dispatch({
            type: FETCH_ERROR,
            payload: error.response.statusText + ' ' + error.response.status + ' ' + error.response.data.error,
          });
        } else {
          // dispatch({type: SIGNOUT_USER_SUCCESS});
          dispatch({ type: FETCH_ERROR, payload: error.message });
        }
        console.log('Error****:', error.message);
      });
  };
};

export const getWorkingsheetContent = workingSheetId => {
  return dispatch => {
    dispatch({ type: FETCH_START });
    axios
      .get('workingSheets/getContent', {
        params: {
          workingSheetId: workingSheetId,
        },
      })
      .then(({ data }) => {
        // console.log(data);
        // console.log("userSignIn: ", data);
        if (data.result) {
          // localStorage.setItem("sheets", JSON.stringify(data.user));
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: SHEET_CONTENT, payload: data.data });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.error });
        }
      })
      .catch(function(error) {
        if (error.response) {
          // dispatch({type: SIGNOUT_USER_SUCCESS});
          dispatch({
            type: FETCH_ERROR,
            payload: error.response.statusText + ' ' + error.response.status + ' ' + error.response.data.error,
          });
        } else {
          // dispatch({type: SIGNOUT_USER_SUCCESS});
          dispatch({ type: FETCH_ERROR, payload: error.message });
        }
        console.log('Error****:', error.message);
      });
  };
};
