/**
 * Created by Rain Laansalu on 17.12.2020.
 */
import { FETCH_ERROR, FETCH_START, FETCH_SUCCESS, SHEETS_DATA } from '../../@jumbo/constants/ActionTypes';
import axios from '../../services/auth/jwt/config';

export const updateWorkingSheets = workingSheets => {
  return dispatch => {
    dispatch({
      type: SHEETS_DATA,
      payload: workingSheets,
    });
  };
};

export const getSidebarWorkingsheets = () => {
  return dispatch => {
    dispatch({ type: FETCH_START });
    dispatch({ type: SHEETS_DATA, payload: [] });
    axios
      .get('workingSheets/getSheets')
      .then(({ data }) => {
        if (data.result && data.sheets) {
          localStorage.setItem('sheets', JSON.stringify(data.user));
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: SHEETS_DATA, payload: data.sheets });
        } else {
          let errorMsg = data.error;
          if (!errorMsg) {
            errorMsg = 'workingSheets/getSheets error';
          }
          dispatch({ type: FETCH_ERROR, payload: errorMsg });
        }
      })
      .catch(function(error) {
        if (error.response) {
          // dispatch({type: SIGNOUT_USER_SUCCESS});
          dispatch({
            type: FETCH_ERROR,
            payload: error.response.statusText + ' ' + error.response.status + ' ' + error.response.data.error,
          });
        } else {
          // dispatch({type: SIGNOUT_USER_SUCCESS});
          dispatch({ type: FETCH_ERROR, payload: error.message });
        }
      });
  };
};
