/**
 * Created by Rain Laansalu on 17.12.2020.
 */
import React from 'react';
import GridContainer from '../../../@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import PageContainer from '../../../@jumbo/components/PageComponents/layouts/PageContainer';
import { useParams } from 'react-router-dom';
import DataGrid from './Components/DataGrid';

const WorkingSheets = () => {
  return <DataGrid id={id} />;
  const { moduleName, id } = useParams();
  return (
    <PageContainer>
      <GridContainer>
        <Grid item xs={12}>
          <DataGrid id={id} />
          {/*<CommonFeatures />*/}
        </Grid>
      </GridContainer>
    </PageContainer>
  );
};

export default WorkingSheets;
