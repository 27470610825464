import { fetchError, fetchStart, fetchSuccess } from '../../../redux/actions';
import { setAuthUser, updateLoadUser } from '../../../redux/actions/Auth';
import React from 'react';
import axios from './config';
import { LOCALSTORAGE_USER } from '../../../@jumbo/constants/ActionTypes';
import { AuhMethods } from '../index';
import { CurrentAuthMethod } from '../../../@jumbo/constants/AppConstants';
import {updateWorkingSheets} from "../../../redux/actions/PageBuilder";

const JWTAuth = {
  onRegister: ({ name, email, password }) => {
    return dispatch => {
      dispatch(fetchStart());
      axios
        .post('register', {
          email: email,
          password: password,
          name: name,
        })
        .then(({ data }) => {
          if (data.status === 'success') {
            // localStorage.setItem('token', data.token.access_token);
            // axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.token.access_token;
            dispatch(fetchSuccess());
            localStorage.removeItem('token');
            dispatch(setAuthUser(null));
          } else {
            dispatch(fetchError(data.msg));
          }
          // if (data.result) {
          //   localStorage.setItem('token', data.token.access_token);
          //   axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.token.access_token;
          //   dispatch(fetchSuccess());
          //   dispatch(JWTAuth.getAuthUser(true, data.token.access_token));
          // } else {
          //   dispatch(fetchError(data.error));
          // }
        })
        .catch(function(error) {
          dispatch(fetchError(error.message));
        });
    };
  },

  onLogin: ({ email, password }) => {
    return dispatch => {
      try {
        dispatch(fetchStart());
        axios
          // .post('auth/login', {
          .post('auth/login', {
            email: email,
            password: password,
          })
          .then(({ data }) => {
            // if (data.status === 'success' && data.token) {
            //   localStorage.setItem('token', data.token);
            //   axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.token;
            //   dispatch(fetchSuccess());
            //   dispatch(JWTAuth.getAuthUser(true, data.token));
            // } else
            if (data.username && data.jwt) {
              localStorage.setItem('token', data.jwt);
              axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.jwt;
              dispatch(fetchSuccess());
              dispatch(JWTAuth.getAuthUser(true, data.jwt));
            } else {
              dispatch(fetchError(data.error));
            }
            // if (data.result) {
            //   localStorage.setItem('token', data.token.access_token);
            //   axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.token.access_token;
            //   dispatch(fetchSuccess());
            //   dispatch(JWTAuth.getAuthUser(true, data.token.access_token));
            // } else {
            //   dispatch(fetchError(data.error));
            // }
          })
          .catch(function(error) {
            console.log(error);
            dispatch(fetchError(error.message));
            if (error.response) {
              dispatch(
                fetchError(error.response.statusText + ' ' + error.response.status + ': ' + error.response.data.error),
              );
            } else {
              dispatch(fetchError(error.message));
            }
          });
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },
  onLogout: () => {
    return dispatch => {
      dispatch(fetchStart());
      axios
        .post('user/logout')
        .then(({ data }) => {
          if (data.result) {
            dispatch(fetchSuccess());
            // localStorage.removeItem('token');
            // localStorage.removeItem(LOCALSTORAGE_USER);
            // dispatch(setAuthUser(null));
            dispatch(AuhMethods[CurrentAuthMethod].removeAuthUser());
          } else {
            dispatch(fetchError(data.error));
          }
        })
        .catch(function(error) {
          dispatch(fetchError(error.message));
        });
    };
  },

  getAuthUser: (loaded = false, token) => {
    return dispatch => {
      axios.interceptors.response.use(
        response => response,
        error => {
          // whatever you want to do with the error
          if (error.response.status === 401 && error.response.statusText === 'Unauthorized') {
            dispatch(AuhMethods[CurrentAuthMethod].removeAuthUser());
          }
          throw error;
        },
      );
      if (token) {
        localStorage.setItem('token', token);
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      }
      if (!token) {
        const token = localStorage.getItem('token');
        if (!token) {
          dispatch(updateLoadUser(true));
          return;
        }
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      }
      dispatch(fetchStart());
      dispatch(updateLoadUser(loaded));
      axios
        .get('auth/me')
        .then(({ data }) => {
          if (data.status === 'success') {
            localStorage.setItem(LOCALSTORAGE_USER, JSON.stringify(data.user));
            dispatch(fetchSuccess());
            dispatch(setAuthUser(data.data));
          } else if (data.result === 'OK') {
            localStorage.setItem(LOCALSTORAGE_USER, JSON.stringify(data.user));
            dispatch(fetchSuccess());
            dispatch(setAuthUser(data.user));
          } else {
            dispatch(updateLoadUser(true));
          }
        })
        .catch(function(error) {
          // console.log(error);
          // dispatch(fetchError(error.message));
          dispatch(AuhMethods[CurrentAuthMethod].removeAuthUser());
        });
      dispatch(updateLoadUser(true));
    };
  },

  removeAuthUser: () => {
    return dispatch => {
      localStorage.removeItem('token');
      localStorage.removeItem(LOCALSTORAGE_USER);
      dispatch(updateWorkingSheets(null));
      dispatch(setAuthUser(null));
      dispatch(updateLoadUser(true));
    };
  },

  onForgotPassword: () => {
    return dispatch => {};
  },
  getSocialMediaIcons: () => {
    return <React.Fragment> </React.Fragment>;
  },
};

export default JWTAuth;
