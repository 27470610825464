import React, { useEffect } from 'react';
import CmtVertical from '../../../../../@coremat/CmtNavigation/Vertical';
import PerfectScrollbar from 'react-perfect-scrollbar';
import makeStyles from '@material-ui/core/styles/makeStyles';
import IntlMessages from '../../../../utils/IntlMessages';
import { PostAdd } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { AuhMethods } from '../../../../../services/auth';
import { CurrentAuthMethod } from '../../../../constants/AppConstants';
import { getSidebarWorkingsheets } from '../../../../../redux/actions/PageBuilder';
import { useIntl } from 'react-intl';

const useStyles = makeStyles(theme => ({
  perfectScrollbarSidebar: {
    height: '100%',
    transition: 'all 0.3s ease',
    '.Cmt-sidebar-fixed &, .Cmt-Drawer-container &': {
      height: 'calc(100% - 167px)',
    },
    '.Cmt-modernLayout &': {
      height: 'calc(100% - 72px)',
    },
    '.Cmt-miniLayout &': {
      height: 'calc(100% - 91px)',
    },
    '.Cmt-miniLayout .Cmt-sidebar-content:hover &': {
      height: 'calc(100% - 167px)',
    },
  },
}));

const SideBar = () => {
  const { authUser } = useSelector(({ auth }) => auth);
  const intl = useIntl();
  const dispatch = useDispatch();
  const { workingsheets } = useSelector(({ pageBuilder }) => pageBuilder);
  useEffect(() => {
    if (authUser && !workingsheets) {
      dispatch(getSidebarWorkingsheets());
    }
  });
  const classes = useStyles();
  let workingSheetMenus = [];
  if (workingsheets && authUser) {
    workingSheetMenus = Object.keys(workingsheets).map((keyName, i) => ({
      name: workingsheets[keyName]['sheetname'],
      icon: <PostAdd />,
      type: 'item',
      link: '/app/workingSheet/' + workingsheets[keyName]['id'],
    }));
  }
  const navigationMenus = [
    {
      name: intl.formatMessage({ id: 'sidebar.main' }),
      type: 'section',
      children: [
        {
          name: intl.formatMessage({ id: 'pages.samplePage' }),
          icon: <PostAdd />,
          type: 'item',
          link: '/sample-page',
        },
      ],
    },
    {
      name: 'Töötabelid',
      type: 'section',
      children: workingSheetMenus,
    },
  ];

  return (
    <PerfectScrollbar className={classes.perfectScrollbarSidebar}>
      <CmtVertical menuItems={navigationMenus} />
    </PerfectScrollbar>
  );
};

export default SideBar;
