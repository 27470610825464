/**
 * Created by Rain Laansalu on 14.12.2020.
 */
import React from 'react';
import { Box, Button, fade, IconButton, makeStyles, Popover, Tooltip, useTheme } from '@material-ui/core';
import AppsIcon from '@material-ui/icons/Apps';
import CmtCard from '../../../../../@coremat/CmtCard';
import CmtCardHeader from '../../../../../@coremat/CmtCard/CmtCardHeader';
import CmtCardContent from '../../../../../@coremat/CmtCard/CmtCardContent';
import CmtGridView from '../../../../../@coremat/CmtGridView';
import CmtAvatar from '../../../../../@coremat/CmtAvatar';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import { useDispatch, useSelector } from 'react-redux';
import EmailIcon from '@material-ui/icons/Email';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import ChatIcon from '@material-ui/icons/Chat';
import { userChangeProject } from '../../../../../redux/actions/Auth';
import { getWorkingsheetDataFromBE } from '../../../../../redux/actions/WorkingSheet';
import CmtImage from '../../../../../@coremat/CmtImage';
import { flags, languageData } from '../LanguageSwitcher/data';
import CmtList from '../../../../../@coremat/CmtList';
import PerfectScrollbar from 'react-perfect-scrollbar';

const useStyles = makeStyles(theme => ({
  cardRoot: {
    '& .Cmt-header-root': {
      paddingTop: 4,
      paddingBottom: 4,
    },
  },
  typography: {
    padding: theme.spacing(2),
  },
  iconRoot: {
    color: fade(theme.palette.common.white, 0.38),
    '&:hover, &:focus': {
      color: theme.palette.common.white,
    },
  },
}));

const useStyles2 = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    padding: '12px 24px',
    '& .flag': {
      fontSize: 30,
    },
    '&:hover': {
      backgroundColor: fade(theme.palette.primary.main, 0.08),
    },
  },
}));

const actions = [
  {
    label: 'More Detail',
  },
  {
    label: 'Close',
  },
];

let projectslist = [];

const ProjectItem = (project, onClick) => {
  const classes = useStyles2();
  return (
    <Box key={project.id} className={clsx(classes.root, 'pointer')} onClick={() => onClick(project.id)}>
      <img src={project.logourl} style={{ maxWidth: '3em' }} />
      <Box ml={3}>{project.legalname}</Box>
    </Box>
  );
};

const ProjectsMenu = () => {
  const classes = useStyles();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const theme = useTheme();
  const dispatch = useDispatch();

  let { authUser } = useSelector(({ auth }) => auth);

  // console.log(authUser.projects);

  const onOpenPopOver = event => {
    setAnchorEl(event.currentTarget);
  };

  const onClosePopOver = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const onClickApp = id => {
    // history.push(path);
    dispatch(userChangeProject(id));
    onClosePopOver();
  };

  return (
    <Box>
      {/*<Tooltip title="Projektid">*/}
      {/*  <IconButton onClick={onOpenPopOver} className={clsx(classes.iconRoot, 'Cmt-appIcon')}>*/}
      {/*    <AppsIcon />*/}
      {/*  </IconButton>*/}
      {/*</Tooltip>*/}
      <Button onClick={onOpenPopOver} color="inherit">
        {authUser && authUser.activeProjectLegalname ? authUser.activeProjectLegalname : ''}
      </Button>
      {/*<IconButton size="small" onClick={onOpenPopOver} data-tut="reactour__localization">*/}
      {/*  <Typography className={classes.userTitle} component="h3" variant="h6">*/}
      {/*    {authUser.activeProjectLegalname}*/}
      {/*  </Typography>*/}
      {/*</IconButton>*/}

      {/*<IconButton size="small" onClick={onOpenPopOver} data-tut="reactour__localization">*/}
      {/*  {authUser && authUser.activeProjectLogoUrl ? (*/}
      {/*    <img src={authUser.activeProjectLogoUrl} style={{ width: '30px' }} />*/}
      {/*  ) : (*/}
      {/*    <Typography variant="body2">No projects found</Typography>*/}
      {/*  )}*/}
      {/*</IconButton>*/}

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={onClosePopOver}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}>
        <CmtCard className={classes.cardRoot}>
          <CmtCardHeader
            title="Projects"
            actionsPos="top-corner"
            actions={actions}
            separator={{
              color: theme.palette.borderColor.dark,
              borderWidth: 1,
              borderStyle: 'solid',
            }}
          />
          <PerfectScrollbar className={classes.perfectScrollbarLanguage}>
            {authUser && authUser.projects.length > 0 ? (
              <CmtList data={authUser.projects} renderRow={item => ProjectItem(item, onClickApp)} />
            ) : (
              <Typography variant="body2">No projects found</Typography>
            )}
          </PerfectScrollbar>
        </CmtCard>
      </Popover>
    </Box>
  );
};

export default ProjectsMenu;
