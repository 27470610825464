import React from 'react';
/**
 * Created by Rain Laansalu on 15.03.2021.
 */

function DateTimeFormatter({ params, type }) {
  let value = params.row[params.column.key];
  let date;

  if (value) {
    date = new Date(value);
    const options = {
      // dateStyle: 'short',
      // weekday: 'long',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    };
    if (type === 'DATETIME') {
      options.hour = '2-digit';
      options.minute = '2-digit';
      options.second = '2-digit';
      value = date.toLocaleTimeString('et-EE', options);
    } else {
      value = date.toLocaleDateString('et-EE', options);
    }
    // value = date.toLocaleDateString('et-EE', options);
  }

  // let date = new Intl.DateTimeFormat("en-GB", {
  //     year: "numeric",
  //     month: "long",
  //     day: "2-digit"
  // }).format(Date.parse(value));
  // let value = obj.value.format('L');

  return <div>{value}</div>;
}

export default DateTimeFormatter;
