import React, { useState } from 'react';
import GridContainer from '../../../@jumbo/components/GridContainer';
import PageContainer from '../../../@jumbo/components/PageComponents/layouts/PageContainer';
import Box from '@material-ui/core/Box';
import IntlMessages from '../../../@jumbo/utils/IntlMessages';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { changeRandomString, increaseQnt } from '../../../redux/actions/WorkingSheet';

const breadcrumbs = [
  { label: 'Home', link: '/' },
  { label: 'Sample Page', isActive: true },
];

const SamplePage = () => {
  // const [simpleText, setSimpleText] = useState('random string');
  const { randomString } = useSelector(({ workingSheet }) => workingSheet);
  const { qnt } = useSelector(({ workingSheet }) => workingSheet);
  const dispatch = useDispatch();
  return (
    <PageContainer heading={<IntlMessages id="pages.samplePage" />} breadcrumbs={breadcrumbs}>
      <GridContainer>
        <Grid item xs={12}>
          <Box>
            <IntlMessages id="pages.samplePage.description" />
            <h3>Count: {qnt}</h3>
            <button onClick={() => dispatch(increaseQnt())}>+</button>
            <textarea defaultValue={randomString} onChange={event => dispatch(changeRandomString(event.target.value))} />
          </Box>
        </Grid>
      </GridContainer>
    </PageContainer>
  );
};

export default SamplePage;
