/**
 * Created by Rain Laansalu on 16.12.2020.
 */
import {
  SHEET_DATA,
  SHEET_DATA_ADD_ALLROWS,
  SHEET_DATA_ADD_ROW,
  SHEET_DATA_CHANGE_ROW,
  SHEET_DATA_DELETE_ROW,
  SHEET_INCREASE_QNT,
} from '../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
  data: null,
  rows: [],
  updatedRowsIndexes: [],
  qnt: 0,
  randomString: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case 'add_random_string': {
      return {
        ...state,
        randomString: action.payload,
      };
    }
    case SHEET_INCREASE_QNT: {
      return {
        ...state,
        qnt: state.qnt + 1,
      };
    }
    case SHEET_DATA: {
      let rows = [];
      if (action.payload.content && Array.isArray(action.payload.content)) {
        rows = action.payload.content;
        delete action.payload.content;
      }
      return {
        ...state,
        data: action.payload,
        rows: rows,
      };
    }
    case SHEET_DATA_ADD_ALLROWS: {
      return {
        ...state,
        rows: action.payload,
      };
    }
    case SHEET_DATA_ADD_ROW: {
      return {
        ...state,
        rows: [...state.rows, action.payload],
      };
    }
    case SHEET_DATA_CHANGE_ROW: {
      const indexes = [];
      return {
        ...state,
        // rows: state.rows.map(
        //     (row, i) => row.id === action.payload.id ? action.payload : row
        // ),
        rows: state.rows.map(function(row, i) {
          if (row.id === action.payload.id) {
            indexes.push(i);
            return action.payload;
          } else {
            return row;
          }
        }),
        updatedRowsIndexes: indexes,
      };
    }
    case SHEET_DATA_DELETE_ROW: {
      return {
        ...state,
        rows: state.rows.filter(row => row.id !== action.payload.id),
      };
    }
    default:
      return state;
  }
};
