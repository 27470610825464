import { FETCH_ERROR, FETCH_START, FETCH_SUCCESS } from '../../@jumbo/constants/ActionTypes';
import axios from '../../services/auth/jwt/config';

export const fetchSuccess = () => {
  return dispatch => {
    dispatch({
      type: FETCH_SUCCESS,
    });
  };
};
export const fetchError = error => {
  return dispatch => {
    dispatch({
      type: FETCH_ERROR,
      payload: error,
    });
  };
};

export const fetchStart = () => {
  return dispatch => {
    dispatch({
      type: FETCH_START,
    });
  };
};

export function searchAutocomplete(term, type, callback) {
  let phpClassMethod = 'suppCl/searchAutocomplete';
  switch (type) {
    case 'users': {
      phpClassMethod = 'user/searchAutocomplete';
      break;
    }
    default: {
      phpClassMethod = 'suppCl/searchAutocomplete';
    }
  }
  axios
    .get(phpClassMethod, {
      params: {
        term: term,
      },
    })
    .then(({ data }) => {
      if (data.data) {
        if (typeof callback === 'function') {
          callback(data.data);
        }
      }
      return null;
    })
    .catch(function(error) {
      console.log('Error****:', error.message);
      return null;
    });
}
