import React from 'react';

/**
 * Created by Rain Laansalu on 18.03.2021.
 */

function IdFormatter({ params, aliasfield }) {
  let value = params.row[aliasfield];
  if (!value) {
    value = params.row['id'];
  }
  return <div>{value}</div>;
}
export default IdFormatter;
