/**
 * Created by Rain Laansalu on 08.03.2021.
 */

import React from 'react';

function SearchInputFormatter({ params }) {
  let value = params.row[params.column.key];
  if (params.row[params.column.key + '__name']) {
    value = params.row[params.column.key + '__name'];
  }
  return <div>{value}</div>;
}
export default SearchInputFormatter;
