/**
 * Created by Rain Laansalu on 10.12.2020.
 */
import eeMessages from '../locales/ee_EE.json';

const EeLang = {
  messages: {
    ...eeMessages,
  },
  locale: 'en-US',
};
export default EeLang;
