/**
 * Created by Rain Laansalu on 22.03.2021.
 */
import React from 'react';
import { useSelector } from 'react-redux';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import GridContainer from '../../../@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import CmtCard from '../../../@coremat/CmtCard';
import CmtCardHeader from '../../../@coremat/CmtCard/CmtCardHeader';
import CmtCardContent from '../../../@coremat/CmtCard/CmtCardContent';
import clsx from 'clsx';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import LinkIcon from '@material-ui/icons/Link';
import LocalPhoneIcon from '@material-ui/icons/LocalPhone';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import { geValidUrl } from '../../../@jumbo/utils/commonHelper';
import IntlMessages, {translate} from '../../../@jumbo/utils/IntlMessages';
import { useIntl } from 'react-intl';

const useStyles = makeStyles(() => ({
  pageFull: {
    width: '100%',
  },
  profileSidebar: {
    '@media screen and (min-width: 1280px) and (max-width: 1499px)': {
      flexBasis: '100%',
      maxWidth: '100%',
    },
  },
  profileMainContent: {
    '@media screen and (min-width: 1280px) and (max-width: 1499px)': {
      flexBasis: '100%',
      maxWidth: '100%',
    },
  },
}));

const Account = () => {
  const classes = useStyles();
  const { authUser } = useSelector(({ auth }) => auth);
  const email = 'tere²tere';
  const website = 'www.www';
  const phone = '555';
  const intl = useIntl();
  return (
    <React.Fragment>
      {authUser && (
        <Box className={classes.pageFull}>
          <GridContainer>
            <Grid item xs={12} lg={6} className={classes.profileSidebar}>
              <Box mb={6}>
                <CmtCard>
                  <CmtCardHeader title={intl.formatMessage({ id: 'popup.account' })} />
                  <CmtCardContent>
                    <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
                      <Box className={classes.iconView}>
                        <MailOutlineIcon />
                      </Box>
                      <Box ml={5}>
                        <Box component="span" fontSize={12} color="text.secondary">
                          <IntlMessages id={'appModule.email'} />
                        </Box>
                        <Box component="p" className={classes.wordAddress} fontSize={16}>
                          <Box component="a" href={`mailto:${email}`}>
                            {authUser.username}
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
                      <Box className={clsx(classes.iconView, 'web')}>
                        <FingerprintIcon />
                      </Box>
                      <Box ml={5}>
                        <Box component="span" fontSize={12} color="text.secondary">
                          <IntlMessages id={'appModule.idcode'} />
                        </Box>
                        <Box component="p" className={classes.wordAddress} fontSize={16}>
                          <Box component="p">{authUser.code}</Box>
                        </Box>
                      </Box>
                    </Box>
                    <Box display="flex" alignItems="center">
                      <Box className={clsx(classes.iconView, 'phone')}>
                        <RecentActorsIcon />
                      </Box>
                      <Box ml={5}>
                        <Box component="span" fontSize={12} color="text.secondary">
                          <IntlMessages id={'appModule.name'} />
                        </Box>
                        <Box component="p" className={classes.wordAddress} fontSize={16} color="text.primary">
                          {authUser.fullname}
                        </Box>
                      </Box>
                    </Box>
                  </CmtCardContent>
                </CmtCard>
              </Box>
            </Grid>
          </GridContainer>
        </Box>
      )}
    </React.Fragment>
  );
};
export default Account;
