import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  SHEETS_DATA,
  UPDATE_AUTH_USER,
  UPDATE_LOAD_USER,
} from '../../@jumbo/constants/ActionTypes';
import axios from '../../services/auth/jwt/config';
import { getSidebarWorkingsheets } from './PageBuilder';
import { fetchError, fetchStart, fetchSuccess } from './Common';
import { AuhMethods } from '../../services/auth';
import { CurrentAuthMethod } from '../../@jumbo/constants/AppConstants';

export const setAuthUser = user => {
  return dispatch => {
    dispatch({
      type: UPDATE_AUTH_USER,
      payload: user,
    });
  };
};

export const updateLoadUser = loading => {
  return dispatch => {
    dispatch({
      type: UPDATE_LOAD_USER,
      payload: loading,
    });
  };
};

export const userChangeProject = projectId => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post('user/changeProject', {
        projectId: projectId,
      })
      .then(({ data }) => {
        if (data.jwt) {
          dispatch(fetchSuccess());
          // localStorage.setItem('token', data.jwt);
          dispatch(AuhMethods[CurrentAuthMethod].getAuthUser(false, data.jwt));
          // axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.jwt;
          dispatch(getSidebarWorkingsheets());
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.error });
        }
      })
      .catch(function(error) {
        dispatch(fetchError(error.message));
      });
  };
};
