import React from 'react';
import { Box, Typography } from '@material-ui/core';
import CmtAvatar from '../../../../@coremat/CmtAvatar';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CmtDropdownMenu from '../../../../@coremat/CmtDropdownMenu';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useDispatch, useSelector } from 'react-redux';
import { AuhMethods } from '../../../../services/auth';
import { CurrentAuthMethod } from '../../../constants/AppConstants';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PersonIcon from '@material-ui/icons/Person';
import IntlMessages from '../../../utils/IntlMessages';
import {useHistory} from "react-router-dom";

const actionsList = [
  {
    path: '/account',
    icon: <PersonIcon />,
    label: <IntlMessages id="popup.account" />,
  },
  {
    icon: <ExitToAppIcon />,
    label: <IntlMessages id="popup.logout" />,
  },
];

const useStyles = makeStyles(theme => ({
  root: {
    padding: '30px 16px 12px 16px',
    borderBottom: `solid 1px ${theme.palette.sidebar.borderColor}`,
  },
  userInfo: {
    paddingTop: 24,
    transition: 'all 0.1s ease',
    height: 75,
    opacity: 1,
    '.Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &': {
      height: 0,
      paddingTop: 0,
      opacity: 0,
      transition: 'all 0.3s ease',
    },
  },
  userTitle: {
    color: theme.palette.sidebar.textDarkColor,
    marginBottom: 8,
  },
  userSubTitle: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: 0.25,
  },
}));

const SidebarHeader = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const onItemClick = item => {
    if (item.label.props.id) {
      switch (item.label.props.id) {
        case 'popup.account': {
          history.push(item.path);
          break;
        }
        case 'popup.logout': {
          dispatch(AuhMethods[CurrentAuthMethod].onLogout());
          break;
        }
        default: {
        }
      }
    }
  };

  let { authUser } = useSelector(({ auth }) => auth);

  let userData = {
    pictureUrl:
      'https://www.gravatar.com/avatar/55d54013933c660c02a859b976da70a4?s=200&d=https%3A%2F%2Faccount.bevira.com%2Fcache%2Fuserpics%2Fnopersonpic.jpg',
    fullname: 'John Doe',
    username: 'john@example.com',
  };
  if (authUser) {
    userData.username = authUser.email;
    if (authUser.username) {
      userData.username = authUser.username;
      userData.fullname = authUser.fullname;
      userData.pictureUrl = authUser.pictureUrl;
    }
    // userData.fullname = authUser.email;
  }

  return (
    <React.Fragment>
      <Box className={classes.root}>
        <CmtAvatar src={userData.pictureUrl} />
        <Box className={classes.userInfo}>
          <CmtDropdownMenu
            onItemClick={onItemClick}
            TriggerComponent={
              <Box display="flex" justifyContent="space-between" alignItems="flex-end">
                <Box mr={2}>
                  <Typography className={classes.userTitle} component="h3" variant="h6">
                    {userData.fullname}
                  </Typography>
                  <Typography className={classes.userSubTitle}>{userData.username}</Typography>
                </Box>
                <ArrowDropDownIcon />
              </Box>
            }
            items={actionsList}
          />
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default SidebarHeader;
